.contentrighttext{
    text-align: start;
  }
  .contentrighttext h2{
    font-size: 30px;
      margin-bottom: 30px;
      font-family:  "Times New Roman";
      font-weight: normal;
      line-height: 70px;
  }
  .text-color {
    color: white; /* Change to your desired color */
  }
  .contentrighttext p{
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      color: white;
      font-size: 21px;
      line-height: 36px;
      margin: 25px 0px 35px 0px;
  }
  .contentWrapper {
    opacity: 0;
    transform: translateX(-100%);
  }
  
  .inView {
    opacity: 1;
    transform: translateY(0);
    animation: slideFromLeft 1s forwards;
  }
  .inView {
    opacity: 1;
    transform: translateY(0);
    animation: slideFromright 1s forwards;
  }
  .app_btn {
    display: flex;
    gap: 20px;
    margin-bottom: 5%;
    align-items: center;

  }
  @keyframes slideFromright{
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }
  .imganimation {
    opacity: 0;
    transform: translateX(-100%);
  }
  
  @keyframes slideFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @media (max-width: 768px) {
    .imganimation img{
      width: 250px;
      height: 550px;
  }
    .contentWrapper{
      margin: 20px 60px;
    }
    .contentrighttext h2{
      font-size: 20px;
    }
    .contentrighttext p{
      font-size: 12px;
    }
    .app_btn {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      margin-bottom: 50px;
    }
  }