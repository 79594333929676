.main {
  padding-top: 50px;
  padding-right: 8%;
  position: relative;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  margin-bottom: 5%;
}
.inView {
  opacity: 1;
  transform: translateY(0);
  animation: slideFromLeft 1s forwards;
}
.main::after {
  content: "";
  width: 100%;
  height: 30vh;
  z-index: 0;
  position: absolute;
  bottom: -100px;
  left: 0;
  background-color: white;
  transform-origin: center;
  transform: skewY(-5deg);
}
.iphoneimg img {
  z-index: 8;
}
.iphoneimg:hover {
  animation: jump 1s infinite;
}
@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
.content {
  text-align: start;
  margin-bottom: 40%;
  margin-left: 14%;
  transition: ease-in 0.5s;
}
.content h1 {
  font-size: 30px;
  margin-bottom: 30px;
  font-family:  "Times New Roman";
  font-weight: normal;
  line-height: 70px;
}
.content p {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 36px;
  margin: 25px 0px 35px 0px;
}
.app_btn {
  display: flex;
  gap: 20px;
}
.contentWrapper {
  opacity: 0;
  transform: translateX(-100%);
  animation: slideFromright 1s forwards;
}

@keyframes slideFromright {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
.imganimation {
  opacity: 0;
  transform: translateX(-100%);
  animation: slideFromLeft 1s forwards;
}

@keyframes slideFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@media (min-width: 820px) and (max-width: 913px) {
  .contentWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 350px;
    gap: 30px;
    margin-left: -120px;
  }
}

@media (max-width: 768px) {
  .main .iphoneimg img {
    width: 200px;
    height: 450px;
  }
  .main h1 {
    font-size: 20px;
  }
  .main p {
    font-size: 12px;
  }
  .app_btn {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-bottom: 220px;
  }
}
