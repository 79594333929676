.contentlefttext{
  text-align: start;
}
.contentlefttext h2{
  font-size:30px;
    margin-bottom: 30px;
    font-family:  "Times New Roman";
    font-weight: normal;
    line-height: 70px;
}
.contentlefttext p{
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 36px;
    margin: 25px 0px 35px 0px;
}

.contentWrapper {
    opacity: 0;
    transform: translateX(-100%);
  }
  .inView {
    opacity: 1;
    transform: translateY(0);
    animation: slideFromLeft 1s forwards;
  }
  .inView {
    opacity: 1;
    transform: translateY(0);
    animation: slideFromright 1s forwards;
  }
  @keyframes slideFromright{
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }
  .imganimation {
    opacity: 0;
    transform: translateX(100%);
  }
  
  @keyframes slideFromLeft {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @media (max-width: 768px) {
    .imganimation img{
        width: 250px;
        height: 550px;
    }
    .contentWrapper{
      margin: 20px 60px;
    }
    .contentlefttext h2{
      font-size: 20px;
    }
    .contentlefttext p{
      font-size: 12px;
    }
  }